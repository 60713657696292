var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"sc-page-sheet"},[_c('v-row',{staticClass:"my-2",attrs:{"align":"center","align-content":"center"}},[_c('i18n',{staticClass:"ml-4 primary--text text-uppercase text-h4",attrs:{"path":'menu.'+_vm.$route.name.toLowerCase()}}),_c('v-spacer'),_c('v-text-field',{staticClass:"mr-4",staticStyle:{"width":"50px"},model:{value:(_vm.keyFilter),callback:function ($$v) {_vm.keyFilter=$$v},expression:"keyFilter"}}),_c('v-btn-toggle',{attrs:{"rounded":"","dense":"","color":"primary"},model:{value:(_vm.opened),callback:function ($$v) {_vm.opened=$$v},expression:"opened"}},[_c('v-btn',{attrs:{"value":false}},[_c('i18n',{attrs:{"path":"label.allvis"}})],1),_c('v-btn',{attrs:{"value":true}},[_c('i18n',{attrs:{"path":"label.openedvis"}})],1)],1),_c('v-btn',{staticClass:"error ma-4",attrs:{"icon":"","dark":"","to":"/"}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-data-table',{staticClass:"transparent table-custom",attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"item-key":"idx","footer-props":_vm.foot_props,"no-data-text":_vm.$t('label.nodata')},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(!item.todate)?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.remove(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-clock-check-outline")])],1):_vm._e()]}}],null,true)},[_c('i18n',{attrs:{"path":"tt.clientout"}})],1)]}},{key:"item.fromdate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("dt-time")(item.fromdate)))])]}},{key:"item.comment",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.details?item.details.comment:''))])]}},{key:"item.todate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("dt-time")(item.todate)))])]}},{key:"item.duration",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("minutes")(_vm.$moment.duration(_vm.$moment(item.todate).diff(_vm.$moment(item.fromdate))).asMinutes())))])]}}])}),_c('sc-confirm-dialog',{attrs:{"mode":"settime"},on:{"click:ok":_vm.remove},model:{value:(_vm.d_confirm),callback:function ($$v) {_vm.d_confirm=$$v},expression:"d_confirm"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }